import "./styles.scss"

import React from "react"

import t from "locale"

const Main = ({ data, lang }) => {
  return (
    <section className="terms-main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {data?.termsContent?.map((item, index) => (
              <div className="terms-main__item" key={index}>
                <h3>{item.title}</h3>
                <a
                  className="button"
                  href={item?.file?.localFile?.publicURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Pobierz", lang)}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
